<template>
<v-container fluid>
  <v-row class="pa-3">
    <v-card elevation="0" class="pa-5" width="100%">
      <v-chart class="chart" :option="option" />
    </v-card>
  </v-row>
</v-container>
</template>

<script>
import {
  use
} from "echarts/core";
import {
  CanvasRenderer
} from "echarts/renderers";
import {
  LineChart
} from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, {
  THEME_KEY
} from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  GridComponent,
  TooltipComponent,
  LegendComponent
]);
import 'echarts/lib/component/visualMap'
export default {
  name: "OkrTrendChart",
  components: {
    VChart
  },
  props: ['data'],
  provide: {
    [THEME_KEY]: "light"
  },
  computed: {
    option: function () {
      let days = [];
      let cp = [];
      let dt = [];
      let ap = [];
      for (let i = 0; i < this.data.companyProgress.length; i++) {
        dt.push(i * 100 / (this.data.companyProgress.length - 1));
      }
      this.data.companyProgress.map(pr => {
        days.push(pr.formatSet);
        cp.push(pr.result)
      })
      this.data.allProgress.map(pr => {
        ap.push(pr.result)
      })
			
      return {
        title: {
          text: 'OKR进度变化趋势图'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: 'white'
            }
          }
        },
        legend: {
          data: ['全部目标', '公司目标', '进度基准线']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: days
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
            name: '全部目标',
            type: 'line',
            areaStyle: {
              color: 'skyblue'
            },
            lineStyle: {
              color: 'blue'
            },
            emphasis: {
              focus: 'series'
            },
            data: ap
          },
          {
            name: '公司目标',
            type: 'line',
            areaStyle: {
              color: 'yellow'
            },
            lineStyle: {
              color: 'orange'
            },
            emphasis: {
              focus: 'series'
            },
            data: cp
          },
          {
            name: '进度基准线',
            type: 'line',
            lineStyle: {
              color: 'grey',
              width: 1,
              type: 'dashed'
            },
            emphasis: {
              focus: 'series'
            },
            data: dt
          },
        ],
      }
    }
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
